/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
// import { Facebook, Instagram } from '@material-ui/icons';

import {gcsBucket} from "../../utils";
import './style.scss'


export default function Footer() {
  return (
    <footer>
      <div>
        Thanks for using the Rambler Project!
      </div>
    </footer>
  );
}
